import React, { Fragment, useState } from "react";
import { stringify } from "qs";
import { serialize } from "dom-form-serializer";
import styles from "./Form.module.scss";

const Form = ({ submitText, formName, affiliate, successMessage }) => {
  const defaultProps = {
    name: "Employee Applications",
    subject: "", // optional subject of the notification email
    action: "",
    errorMessage:
      "There is a problem, your application has not been sent, please try contacting us via email",
  };

  const [alert, setAlert] = useState("");
  const [disabled, setDisabled] = useState(false);

  const submitWaitlist = (data) => {
    var request = new XMLHttpRequest();
    const date = new Date();
    request.open(
      "POST",
      "https://hook.eu1.make.com/ma5c7wx5ruknnl2w4o5ldaar881jx9jp"
    );
    request.setRequestHeader("Content-type", "application/json");
    var params = {
      context: "waitlist",
      email: data.email,
      title: data.title,
      added: date,
      affiliate: affiliate?.length > 0 ? affiliate : "",
    };

    request.send(JSON.stringify(params));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (disabled) return;

    const form = e.target;
    const data = serialize(form);
    formName === "Waitlist" && submitWaitlist(data);
    setDisabled(true);
    form.reset();
    setAlert(successMessage);
    setDisabled(false);
    if (formName !== "Waitlist") {
      fetch(form.action + "?" + stringify(data), {
        method: "POST",
      })
        .then((res) => {
          if (res.ok) {
            return res;
          } else {
            throw new Error("Network error");
          }
        })
        .then(() => {
          form.reset();
          setAlert(successMessage);
          setDisabled(false);
        })
        .catch((err) => {
          console.error(err);
          setDisabled(false);
          setAlert(defaultProps.errorMessage);
        });
    }
  };

  return (
    <Fragment>
      <form
        className={styles.form}
        name={formName}
        method="POST"
        action={defaultProps.action}
        onSubmit={(e) => {
          handleSubmit(e);
        }}
        data-netlify="true"
      >
        {alert && <div className={styles.formAlert}>{alert}</div>}

        <label className={styles.formLabel}>
          <span>Name*</span>
          <input
            className={`${styles.formInput} ${styles.formInputText}`}
            type="name"
            placeholder="John Smith"
            name="name"
            required
          />
        </label>

        <label className={styles.formLabel}>
          <span>Email*</span>
          <input
            className={`${styles.formInput} ${styles.formInputText}`}
            type="email"
            placeholder="john@company.com"
            name="email"
            required
          />
        </label>

        <label className={styles.formLabel}>
          <span>Additional information</span>
          <textarea
            className={`${styles.formInput} ${styles.formInputText} ${styles.textArea}`}
            type="text"
            placeholder="Tell me more about your request..."
            name="message"
          />
        </label>
        <p>
          {" "}
          I care about your privacy and will{" "}
          <span style={{ textDecoration: "underline" }}>not</span> use your
          details for marketing purposes. To learn more, see the{" "}
          <a href="/privacy-policy">privacy policy</a>.
        </p>

        <input type="hidden" name="form-name" value={formName} />
        <input
          className={styles.button}
          type="submit"
          value={submitText ? submitText : "Submit"}
          disabled={disabled}
        />
      </form>
    </Fragment>
  );
};

export default Form;
