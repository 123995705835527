import React, { useState } from "react";
import styles from "./Contact.module.scss";
import Form from "../common/Form";

const ContactForm = () => {
  return (
    <div className={styles.header}>
      <div className={styles.headerContent}>
        <div className={styles.heading}>
          <h1>Contact</h1>
          <p>
            Please provide me with information about your request and I’ll get
            in touch shortly.
          </p>
        </div>
        <Form
          submitText={"Get in touch"}
          formName={"Contact"}
          successMessage={
            "Thank you for your message. I will get back to you shortly."
          }
        />
      </div>
    </div>
  );
};

export default ContactForm;
