import React, { useState } from "react";
import Layout from "../components/common/Layout";
import Fade from "react-reveal/Fade";
import ContactForm from "../components/contact/Contact";
import Content from "../components/contact/Content";

const ContactPage = () => {
  return (
    <Layout
      meta={{
        description:
          "Museum-quality posters made on thick matte paper. Add a wonderful accent to your room and office with these posters that are sure to brighten any environment.",
        title: "Contact | LWE Art",
        type: "website",
      }}
      title={"Contact | LWE Art"}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <Content />
        </Fade>
      </main>
    </Layout>
  );
};

export default ContactPage;
