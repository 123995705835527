import React from "react";
import styles from "./Content.module.scss";
import ContactForm from "./Contact";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { CheckIcon } from "@heroicons/react/24/solid";

const Content = () => {
  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <ContactForm />
        <div className={styles.rightSection}>
          <div>
            <h3>Do you have any questions?</h3>
            <ul>
              <li className={styles.listItem}>
                <CheckIcon width={26} /> Discuss potential art exhibitions and
                collaborations with me
              </li>
              <li className={styles.listItem}>
                <CheckIcon width={26} /> Discover how my art can be used in your
                projects or events
              </li>
              <li className={styles.listItem}>
                <CheckIcon width={26} /> Learn more about my art and the stories
                behind it
              </li>
            </ul>
            <p className={styles.subline}>
              <CheckCircleIcon width={26} /> I am ready to help you!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
